import { t } from 'i18next'
import React from 'react'
import DashboardService, { useIFrameUrl } from '../../services/dashboard/Dashboard'

const Iframe = () => {

    const {data:urlData} = useIFrameUrl();

    return (
        <div className="newsMainBg">
            {urlData ?
                <div style={{ width: '100%', height: '80vh', margin: 0 }}>
                    <iframe
                        src={urlData}
                        title="Site"
                        width="100%"
                        height="100%"
                        style={{ border: 'none' }}
                    ></iframe>
                </div>
                :
                <>
                    <img src="images/download-no-data.png" alt="" />
                    <div>{t("sorry_no_data_found")}</div>
                </>
            }
        </div>
    )
}

export default Iframe