
import React from 'react'
import { Modal } from 'react-bootstrap'
import Vimeo from '@u-wave/react-vimeo';
import { toast } from 'react-toastify';

export default function VideoModal({ show, handleClose, video }) {
    return (
        <div>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{video?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {video?.url &&
                        <Vimeo
                            video={video?.url}
                            autoplay
                            onError={() => {
                                toast.error("some error occured")
                                handleClose()
                            }}
                            className='vimeo-player d-flex justify-content-center'
                            width={700}
                        />}
                </Modal.Body>
            </Modal>
        </div>

    )
}
