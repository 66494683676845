// const BASE_URL = "http://192.168.7.19:5005/node_api/";
// const BASE_URL = "http://192.168.21.88:5009/node_api/";
// const BASE_URL = "http://162.19.146.134:1748/node_api/";
const BASE_URL = "https://admin.xmlm.info/node_api/";

const DEFAULT_KEY = '14959'


const PAYPAL_CLIENT_ID = "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75"

export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID }
