import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enCommon from '../src/locales/en/en_common.json';
import enEwallet from '../src/locales/en/en_ewallet.json';
import enDashboard from '../src/locales/en/en_dashboard.json'
import enProfile from '../src/locales/en/en_profile.json'
import enPayout from '../src/locales/en/en_payout.json'
import enEpin from '../src/locales/en/en_epin.json'
import enRegister from '../src/locales/en/en_register.json'
import enTree from '../src/locales/en/en_tree.json'
import enError from '../src/locales/en/en_error.json'
import enShopping from '../src/locales/en/en_shopping.json'
import enReplica from '../src/locales/en/en_replica.json'
import enMailbox from '../src/locales/en/en_mailBox.json'
import enLeads from '../src/locales/en/en_leads.json'
import enSubscription from '../src/locales/en/en_subscription.json'
import enTicket from '../src/locales/en/en_tickets.json'
import enCrm from '../src/locales/en/en_crm.json'
import enTools from "../src/locales/en/en_tools.json"

import esCommon from '../src/locales/es/es_common.json'
import esDashboard from '../src/locales/es/es_dashboard.json'
import esProfile from '../src/locales/es/es_profile.json'
import esEwallet from '../src/locales/es/es_ewallet.json'
import esEpin from '../src/locales/es/es_epin.json'
import esRegister from '../src/locales/es/es_register.json'
import esTree from '../src/locales/es/es_tree.json'
import esError from '../src/locales/es/es_error.json'
import esPayout from '../src/locales/es/es_payout.json'
import esShopping from '../src/locales/es/es_shopping.json'
import esLeads from '../src/locales/es/es_leads.json'
import esSubscription from '../src/locales/es/es_subscription.json'
import esReplica from '../src/locales/es/es_replica.json'
import esMailbox from '../src/locales/es/es_mailBox.json'
import esTicket from '../src/locales/es/es_tickets.json'
import esCrm from '../src/locales/es/es_crm.json'
import esTools from "../src/locales/es/es_tools.json"

import arCommon from '../src/locales/ar/ar_common.json'
import arDashboard from '../src/locales/ar/ar_dashboard.json'
import arProfile from '../src/locales/ar/ar_profile.json'
import arEwallet from '../src/locales/ar/ar_ewallet.json'
import arEpin from '../src/locales/ar/ar_epin.json'
import arRegister from '../src/locales/ar/ar_register.json'
import arTree from '../src/locales/ar/ar_tree.json'
import arPayout from '../src/locales/ar/ar_payout.json'
import arError from '../src/locales/ar/ar_error.json'
import arShopping from '../src/locales/ar/ar_shopping.json'
import arLeads from '../src/locales/ar/ar_leads.json'
import arSubscription from '../src/locales/ar/ar_subscription.json'
import arReplica from '../src/locales/ar/ar_replica.json'
import arMailbox from '../src/locales/ar/ar_mailBox.json'
import arTicket from '../src/locales/ar/ar_tickets.json'
import arCrm from '../src/locales/ar/ar_crm.json'
import arTools from "../src/locales/ar/ar_tools.json"

import chCommon from '../src/locales/ch/ch_common.json'
import chDashboard from '../src/locales/ch/ch_dashboard.json'
import chProfile from '../src/locales/ch/ch_profile.json'
import chEwallet from '../src/locales/ch/ch_ewallet.json'
import chEpin from '../src/locales/ch/ch_epin.json'
import chRegister from '../src/locales/ch/ch_register.json'
import chTree from '../src/locales/ch/ch_tree.json'
import chPayout from '../src/locales/ch/ch_payout.json'
import chError from '../src/locales/ch/ch_error.json'
import chShopping from '../src/locales/ch/ch_shopping.json'
import chLeads from '../src/locales/ch/ch_leads.json'
import chSubscription from '../src/locales/ch/ch_subscription.json'
import chReplica from '../src/locales/ch/ch_replica.json'
import chMailbox from '../src/locales/ch/ch_mailBox.json'
import chTicket from '../src/locales/ch/ch_tickets.json'
import chCrm from '../src/locales/ch/ch_crm.json'
import chTools from "../src/locales/ch/ch_tools.json"

import deCommon from '../src/locales/de/de_common.json'
import deDashboard from '../src/locales/de/de_dashboard.json'
import deProfile from '../src/locales/de/de_profile.json'
import deEwallet from '../src/locales/de/de_ewallet.json'
import deEpin from '../src/locales/de/de_epin.json'
import deRegister from '../src/locales/de/de_register.json'
import deTree from '../src/locales/de/de_tree.json'
import dePayout from '../src/locales/de/de_payout.json'
import deError from '../src/locales/de/de_error.json'
import deShopping from '../src/locales/de/de_shopping.json'
import deLeads from '../src/locales/de/de_leads.json'
import deSubscription from '../src/locales/de/de_subscription.json'
import deReplica from '../src/locales/de/de_replica.json'
import deMailbox from '../src/locales/de/de_mailBox.json'
import deTicket from '../src/locales/de/de_tickets.json'
import deCrm from '../src/locales/de/de_crm.json'
import deTools from "../src/locales/de/de_tools.json"

import frCommon from '../src/locales/fr/fr_common.json'
import frDashboard from '../src/locales/fr/fr_dashboard.json'
import frProfile from '../src/locales/fr/fr_profile.json'
import frEwallet from '../src/locales/fr/fr_ewallet.json'
import frEpin from '../src/locales/fr/fr_epin.json'
import frRegister from '../src/locales/fr/fr_register.json'
import frTree from '../src/locales/fr/fr_tree.json'
import frPayout from '../src/locales/fr/fr_payout.json'
import frError from '../src/locales/fr/fr_error.json'
import frShopping from '../src/locales/fr/fr_shopping.json'
import frLeads from '../src/locales/fr/fr_leads.json'
import frSubscription from '../src/locales/fr/fr_subscription.json'
import frReplica from '../src/locales/fr/fr_replica.json'
import frMailbox from '../src/locales/fr/fr_mailBox.json'
import frTicket from '../src/locales/fr/fr_tickets.json'
import frCrm from '../src/locales/fr/fr_crm.json'
import frTools from '../src/locales/fr/fr_tools.json'

import itCommon from '../src/locales/it/it_common.json'
import itDashboard from '../src/locales/it/it_dashboard.json'
import itProfile from '../src/locales/it/it_profile.json'
import itEwallet from '../src/locales/it/it_ewallet.json'
import itEpin from '../src/locales/it/it_epin.json'
import itRegister from '../src/locales/it/it_register.json'
import itTree from '../src/locales/it/it_tree.json'
import itPayout from '../src/locales/it/it_payout.json'
import itError from '../src/locales/it/it_error.json'
import itShopping from '../src/locales/it/it_shopping.json'
import itLeads from '../src/locales/it/it_leads.json'
import itSubscription from '../src/locales/it/it_subscription.json'
import itReplica from '../src/locales/it/it_replica.json'
import itMailbox from '../src/locales/it/it_mailBox.json'
import itTicket from '../src/locales/it/it_tickets.json'
import itCrm from '../src/locales/it/it_crm.json'
import itTools from '../src/locales/it/it_tools.json'

import poCommon from '../src/locales/po/po_common.json'
import poDashboard from '../src/locales/po/po_dashboard.json'
import poProfile from '../src/locales/po/po_profile.json'
import poEwallet from '../src/locales/po/po_ewallet.json'
import poEpin from '../src/locales/po/po_epin.json'
import poRegister from '../src/locales/po/po_register.json'
import poTree from '../src/locales/po/po_tree.json'
import poPayout from '../src/locales/po/po_payout.json'
import poError from '../src/locales/po/po_error.json'
import poShopping from '../src/locales/po/po_shopping.json'
import poLeads from '../src/locales/po/po_leads.json'
import poSubscription from '../src/locales/po/po_subscription.json'
import poReplica from '../src/locales/po/po_replica.json'
import poMailbox from '../src/locales/po/po_mailBox.json'
import poTicket from '../src/locales/po/po_tickets.json'
import poCrm from '../src/locales/po/po_crm.json'
import poTools from '../src/locales/po/po_tools.json'


import ptCommon from '../src/locales/pt/pt_common.json'
import ptDashboard from '../src/locales/pt/pt_dashboard.json'
import ptProfile from '../src/locales/pt/pt_profile.json'
import ptEwallet from '../src/locales/pt/pt_ewallet.json'
import ptEpin from '../src/locales/pt/pt_epin.json'
import ptRegister from '../src/locales/pt/pt_register.json'
import ptTree from '../src/locales/pt/pt_tree.json'
import ptPayout from '../src/locales/pt/pt_payout.json'
import ptError from '../src/locales/pt/pt_error.json'
import ptShopping from '../src/locales/pt/pt_shopping.json'
import ptLeads from '../src/locales/pt/pt_leads.json'
import ptSubscription from '../src/locales/pt/pt_subscription.json'
import ptReplica from '../src/locales/pt/pt_replica.json'
import ptMailbox from '../src/locales/pt/pt_mailBox.json'
import ptTicket from '../src/locales/pt/pt_tickets.json'
import ptCrm from '../src/locales/pt/pt_crm.json'
import ptTools from '../src/locales/pt/pt_tools.json'

import ruCommon from '../src/locales/ru/ru_common.json'
import ruDashboard from '../src/locales/ru/ru_dashboard.json'
import ruProfile from '../src/locales/ru/ru_profile.json'
import ruEwallet from '../src/locales/ru/ru_ewallet.json'
import ruEpin from '../src/locales/ru/ru_epin.json'
import ruRegister from '../src/locales/ru/ru_register.json'
import ruTree from '../src/locales/ru/ru_tree.json'
import ruPayout from '../src/locales/ru/ru_payout.json'
import ruError from '../src/locales/ru/ru_error.json'
import ruShopping from '../src/locales/ru/ru_shopping.json'
import ruLeads from '../src/locales/ru/ru_leads.json'
import ruSubscription from '../src/locales/ru/ru_subscription.json'
import ruReplica from '../src/locales/ru/ru_replica.json'
import ruMailbox from '../src/locales/ru/ru_mailBox.json'
import ruTicket from '../src/locales/ru/ru_tickets.json'
import ruCrm from '../src/locales/ru/ru_crm.json'
import ruTools from '../src/locales/ru/ru_tools.json'

import trCommon from '../src/locales/tr/tr_common.json'
import trDashboard from '../src/locales/tr/tr_dashboard.json'
import trProfile from '../src/locales/tr/tr_profile.json'
import trEwallet from '../src/locales/tr/tr_ewallet.json'
import trEpin from '../src/locales/tr/tr_epin.json'
import trRegister from '../src/locales/tr/tr_register.json'
import trTree from '../src/locales/tr/tr_tree.json'
import trPayout from '../src/locales/tr/tr_payout.json'
import trError from '../src/locales/tr/tr_error.json'
import trShopping from '../src/locales/tr/tr_shopping.json'
import trLeads from '../src/locales/tr/tr_leads.json'
import trSubscription from '../src/locales/tr/tr_subscription.json'
import trReplica from '../src/locales/tr/tr_replica.json'
import trMailbox from '../src/locales/tr/tr_mailBox.json'
import trTicket from '../src/locales/tr/tr_tickets.json'
import trCrm from '../src/locales/tr/tr_crm.json'
import trTools from '../src/locales/tr/tr_tools.json'


import noCommon from '../src/locales/no/no_common.json'
import noCrm from '../src/locales/no/no_crm.json'
import noDashboard from '../src/locales/no/no_dashboard.json'
import noEpin from '../src/locales/no/no_epin.json'
import noError from '../src/locales/no/no_error.json'
import noProfile from '../src/locales/no/no_profile.json'
import noEwallet from '../src/locales/no/no_ewallet.json'
import noRegister from '../src/locales/no/no_register.json'
import noTree from '../src/locales/no/no_tree.json'
import noPayout from '../src/locales/no/no_payout.json'
import noShopping from '../src/locales/no/no_shopping.json'
import noLeads from '../src/locales/no/no_leads.json'
import noSubscription from '../src/locales/no/no_subscription.json'
import noReplica from '../src/locales/no/no_replica.json'
import noMailbox from '../src/locales/no/no_mailBox.json'
import noTicket from '../src/locales/no/no_tickets.json'
import noTools from '../src/locales/no/no_tools.json'

import svCommon from '../src/locales/sv/sv_common.json'
import svCrm from '../src/locales/sv/sv_crm.json'
import svDashboard from '../src/locales/sv/sv_dashboard.json'
import svEpin from '../src/locales/sv/sv_epin.json'
import svError from '../src/locales/sv/sv_error.json'
import svProfile from '../src/locales/sv/sv_profile.json'
import svEwallet from '../src/locales/sv/sv_ewallet.json'
import svRegister from '../src/locales/sv/sv_register.json'
import svTree from '../src/locales/sv/sv_tree.json'
import svPayout from '../src/locales/sv/sv_payout.json'
import svShopping from '../src/locales/sv/sv_shopping.json'
import svLeads from '../src/locales/sv/sv_leads.json'
import svSubscription from '../src/locales/sv/sv_subscription.json'
import svReplica from '../src/locales/sv/sv_replica.json'
import svMailbox from '../src/locales/sv/sv_mailBox.json'
import svTicket from '../src/locales/sv/sv_tickets.json'
import svTools from '../src/locales/sv/sv_tools.json'

import thCommon from '../src/locales/th/th_common.json'
import thCrm from '../src/locales/th/th_crm.json'
import thDashboard from '../src/locales/th/th_dashboard.json'
import thEpin from '../src/locales/th/th_epin.json'
import thError from '../src/locales/th/th_error.json'
import thProfile from '../src/locales/th/th_profile.json'
import thEwallet from '../src/locales/th/th_ewallet.json'
import thRegister from '../src/locales/th/th_register.json'
import thTree from '../src/locales/th/th_tree.json'
import thPayout from '../src/locales/th/th_payout.json'
import thShopping from '../src/locales/th/th_shopping.json'
import thLeads from '../src/locales/th/th_leads.json'
import thSubscription from '../src/locales/th/th_subscription.json'
import thReplica from '../src/locales/th/th_replica.json'
import thMailbox from '../src/locales/th/th_mailBox.json'
import thTicket from '../src/locales/th/th_tickets.json'
import thTools from '../src/locales/th/th_tools.json'

import viCommon from '../src/locales/vi/vi_common.json'
import viCrm from '../src/locales/vi/vi_crm.json'
import viDashboard from '../src/locales/vi/vi_dashboard.json'
import viEpin from '../src/locales/vi/vi_epin.json'
import viError from '../src/locales/vi/vi_error.json'
import viProfile from '../src/locales/vi/vi_profile.json'
import viEwallet from '../src/locales/vi/vi_ewallet.json'
import viRegister from '../src/locales/vi/vi_register.json'
import viTree from '../src/locales/vi/vi_tree.json'
import viPayout from '../src/locales/vi/vi_payout.json'
import viShopping from '../src/locales/vi/vi_shopping.json'
import viLeads from '../src/locales/vi/vi_leads.json'
import viSubscription from '../src/locales/vi/vi_subscription.json'
import viReplica from '../src/locales/vi/vi_replica.json'
import viMailbox from '../src/locales/vi/vi_mailBox.json'
import viTicket from '../src/locales/vi/vi_tickets.json'
import viTools from '../src/locales/vi/vi_tools.json'

import srCommon from '../src/locales/sr/sr_common.json'
import srCrm from '../src/locales/sr/sr_crm.json'
import srDashboard from '../src/locales/sr/sr_dashboard.json'
import srEpin from '../src/locales/sr/sr_epin.json'
import srError from '../src/locales/sr/sr_error.json'
import srProfile from '../src/locales/sr/sr_profile.json'
import srEwallet from '../src/locales/sr/sr_ewallet.json'
import srRegister from '../src/locales/sr/sr_register.json'
import srTree from '../src/locales/sr/sr_tree.json'
import srPayout from '../src/locales/sr/sr_payout.json'
import srShopping from '../src/locales/sr/sr_shopping.json'
import srLeads from '../src/locales/sr/sr_leads.json'
import srSubscription from '../src/locales/sr/sr_subscription.json'
import srReplica from '../src/locales/sr/sr_replica.json'
import srMailbox from '../src/locales/sr/sr_mailBox.json'
import srTicket from '../src/locales/sr/sr_tickets.json'
import srTools from '../src/locales/sr/sr_tools.json'

import mkCommon from '../src/locales/mk/mk_common.json'
import mkCrm from '../src/locales/mk/mk_crm.json'
import mkDashboard from '../src/locales/mk/mk_dashboard.json'
import mkEpin from '../src/locales/mk/mk_epin.json'
import mkError from '../src/locales/mk/mk_error.json'
import mkProfile from '../src/locales/mk/mk_profile.json'
import mkEwallet from '../src/locales/mk/mk_ewallet.json'
import mkRegister from '../src/locales/mk/mk_register.json'
import mkTree from '../src/locales/mk/mk_tree.json'
import mkPayout from '../src/locales/mk/mk_payout.json'
import mkShopping from '../src/locales/mk/mk_shopping.json'
import mkLeads from '../src/locales/mk/mk_leads.json'
import mkSubscription from '../src/locales/mk/mk_subscription.json'
import mkReplica from '../src/locales/mk/mk_replica.json'
import mkMailbox from '../src/locales/mk/mk_mailBox.json'
import mkTicket from '../src/locales/mk/mk_tickets.json'
import mkTools from '../src/locales/mk/mk_tools.json'

import sqCommon from '../src/locales/sq/sq_common.json'
import sqCrm from '../src/locales/sq/sq_crm.json'
import sqDashboard from '../src/locales/sq/sq_dashboard.json'
import sqEpin from '../src/locales/sq/sq_epin.json'
import sqError from '../src/locales/sq/sq_error.json'
import sqProfile from '../src/locales/sq/sq_profile.json'
import sqEwallet from '../src/locales/sq/sq_ewallet.json'
import sqRegister from '../src/locales/sq/sq_register.json'
import sqTree from '../src/locales/sq/sq_tree.json'
import sqPayout from '../src/locales/sq/sq_payout.json'
import sqShopping from '../src/locales/sq/sq_shopping.json'
import sqLeads from '../src/locales/sq/sq_leads.json'
import sqSubscription from '../src/locales/sq/sq_subscription.json'
import sqReplica from '../src/locales/sq/sq_replica.json'
import sqMailbox from '../src/locales/sq/sq_mailBox.json'
import sqTicket from '../src/locales/sq/sq_tickets.json'
import sqTools from '../src/locales/sq/sq_tools.json'

import slCommon from '../src/locales/sl/sl_common.json'
import slCrm from '../src/locales/sl/sl_crm.json'
import slDashboard from '../src/locales/sl/sl_dashboard.json'
import slEpin from '../src/locales/sl/sl_epin.json'
import slError from '../src/locales/sl/sl_error.json'
import slProfile from '../src/locales/sl/sl_profile.json'
import slEwallet from '../src/locales/sl/sl_ewallet.json'
import slRegister from '../src/locales/sl/sl_register.json'
import slTree from '../src/locales/sl/sl_tree.json'
import slPayout from '../src/locales/sl/sl_payout.json'
import slShopping from '../src/locales/sl/sl_shopping.json'
import slLeads from '../src/locales/sl/sl_leads.json'
import slSubscription from '../src/locales/sl/sl_subscription.json'
import slReplica from '../src/locales/sl/sl_replica.json'
import slMailbox from '../src/locales/sl/sl_mailBox.json'
import slTicket from '../src/locales/sl/sl_tickets.json'
import slTools from '../src/locales/sl/sl_tools.json'

import hrCommon from '../src/locales/hr/hr_common.json'
import hrCrm from '../src/locales/hr/hr_crm.json'
import hrDashboard from '../src/locales/hr/hr_dashboard.json'
import hrEpin from '../src/locales/hr/hr_epin.json'
import hrError from '../src/locales/hr/hr_error.json'
import hrProfile from '../src/locales/hr/hr_profile.json'
import hrEwallet from '../src/locales/hr/hr_ewallet.json'
import hrRegister from '../src/locales/hr/hr_register.json'
import hrTree from '../src/locales/hr/hr_tree.json'
import hrPayout from '../src/locales/hr/hr_payout.json'
import hrShopping from '../src/locales/hr/hr_shopping.json'
import hrLeads from '../src/locales/hr/hr_leads.json'
import hrSubscription from '../src/locales/hr/hr_subscription.json'
import hrReplica from '../src/locales/hr/hr_replica.json'
import hrMailbox from '../src/locales/hr/hr_mailBox.json'
import hrTicket from '../src/locales/hr/hr_tickets.json'
import hrTools from '../src/locales/hr/hr_tools.json'

import skCommon from '../src/locales/sk/sk_common.json'
import skCrm from '../src/locales/sk/sk_crm.json'
import skDashboard from '../src/locales/sk/sk_dashboard.json'
import skEpin from '../src/locales/sk/sk_epin.json'
import skError from '../src/locales/sk/sk_error.json'
import skProfile from '../src/locales/sk/sk_profile.json'
import skEwallet from '../src/locales/sk/sk_ewallet.json'
import skRegister from '../src/locales/sk/sk_register.json'
import skTree from '../src/locales/sk/sk_tree.json'
import skPayout from '../src/locales/sk/sk_payout.json'
import skShopping from '../src/locales/sk/sk_shopping.json'
import skLeads from '../src/locales/sk/sk_leads.json'
import skSubscription from '../src/locales/sk/sk_subscription.json'
import skReplica from '../src/locales/sk/sk_replica.json'
import skMailbox from '../src/locales/sk/sk_mailBox.json'
import skTicket from '../src/locales/sk/sk_tickets.json'
import skTools from '../src/locales/sk/sk_tools.json'

import elCommon from '../src/locales/el/el_common.json'
import elCrm from '../src/locales/el/el_crm.json'
import elDashboard from '../src/locales/el/el_dashboard.json'
import elEpin from '../src/locales/el/el_epin.json'
import elError from '../src/locales/el/el_error.json'
import elProfile from '../src/locales/el/el_profile.json'
import elEwallet from '../src/locales/el/el_ewallet.json'
import elRegister from '../src/locales/el/el_register.json'
import elTree from '../src/locales/el/el_tree.json'
import elPayout from '../src/locales/el/el_payout.json'
import elShopping from '../src/locales/el/el_shopping.json'
import elLeads from '../src/locales/el/el_leads.json'
import elSubscription from '../src/locales/el/el_subscription.json'
import elReplica from '../src/locales/el/el_replica.json'
import elMailbox from '../src/locales/el/el_mailBox.json'
import elTicket from '../src/locales/el/el_tickets.json'
import elTools from '../src/locales/el/el_tools.json'

import csCommon from '../src/locales/cs/cs_common.json'
import csCrm from '../src/locales/cs/cs_crm.json'
import csDashboard from '../src/locales/cs/cs_dashboard.json'
import csEpin from '../src/locales/cs/cs_epin.json'
import csError from '../src/locales/cs/cs_error.json'
import csProfile from '../src/locales/cs/cs_profile.json'
import csEwallet from '../src/locales/cs/cs_ewallet.json'
import csRegister from '../src/locales/cs/cs_register.json'
import csTree from '../src/locales/cs/cs_tree.json'
import csPayout from '../src/locales/cs/cs_payout.json'
import csShopping from '../src/locales/cs/cs_shopping.json'
import csLeads from '../src/locales/cs/cs_leads.json'
import csSubscription from '../src/locales/cs/cs_subscription.json'
import csReplica from '../src/locales/cs/cs_replica.json'
import csMailbox from '../src/locales/cs/cs_mailBox.json'
import csTicket from '../src/locales/cs/cs_tickets.json'
import csTools from '../src/locales/cs/cs_tools.json'

import bgCommon from '../src/locales/bg/bg_common.json'
import bgCrm from '../src/locales/bg/bg_crm.json'
import bgDashboard from '../src/locales/bg/bg_dashboard.json'
import bgEpin from '../src/locales/bg/bg_epin.json'
import bgError from '../src/locales/bg/bg_error.json'
import bgProfile from '../src/locales/bg/bg_profile.json'
import bgEwallet from '../src/locales/bg/bg_ewallet.json'
import bgRegister from '../src/locales/bg/bg_register.json'
import bgTree from '../src/locales/bg/bg_tree.json'
import bgPayout from '../src/locales/bg/bg_payout.json'
import bgShopping from '../src/locales/bg/bg_shopping.json'
import bgLeads from '../src/locales/bg/bg_leads.json'
import bgSubscription from '../src/locales/bg/bg_subscription.json'
import bgReplica from '../src/locales/bg/bg_replica.json'
import bgMailbox from '../src/locales/bg/bg_mailBox.json'
import bgTicket from '../src/locales/bg/bg_tickets.json'
import bgTools from '../src/locales/bg/bg_tools.json'

import roCommon from '../src/locales/ro/ro_common.json'
import roCrm from '../src/locales/ro/ro_crm.json'
import roDashboard from '../src/locales/ro/ro_dashboard.json'
import roEpin from '../src/locales/ro/ro_epin.json'
import roError from '../src/locales/ro/ro_error.json'
import roProfile from '../src/locales/ro/ro_profile.json'
import roEwallet from '../src/locales/ro/ro_ewallet.json'
import roRegister from '../src/locales/ro/ro_register.json'
import roTree from '../src/locales/ro/ro_tree.json'
import roPayout from '../src/locales/ro/ro_payout.json'
import roShopping from '../src/locales/ro/ro_shopping.json'
import roLeads from '../src/locales/ro/ro_leads.json'
import roSubscription from '../src/locales/ro/ro_subscription.json'
import roReplica from '../src/locales/ro/ro_replica.json'
import roMailbox from '../src/locales/ro/ro_mailBox.json'
import roTicket from '../src/locales/ro/ro_tickets.json'
import roTools from '../src/locales/ro/ro_tools.json'

const savedLanguage = JSON.parse(localStorage.getItem('userLanguage'));

const mergedTranslations = {
  en: {
    translation: { ...enCommon, ...enEwallet, ...enDashboard, ...enPayout, ...enEpin, ...enProfile, ...enRegister, ...enTree, ...enError, ...enShopping, ...enReplica, ...enLeads, ...enMailbox, ...enSubscription, ...enTicket, ...enCrm, ...enTools },
  },
  es: {
    translation: { ...esCommon, ...esDashboard, ...esProfile, ...esEwallet, ...esEpin, ...esRegister, ...esTree, ...esPayout, ...esError, ...esShopping, ...esReplica, ...esLeads, ...esMailbox, ...esSubscription, ...esTicket, ...esCrm, ...esTools }
  },
  ar: {
    translation: { ...arCommon, ...arDashboard, ...arProfile, ...arEwallet, ...arEpin, ...arRegister, ...arTree, ...arPayout, ...arError, ...arShopping, ...arReplica, ...arLeads, ...arMailbox, ...arSubscription, ...arTicket, ...arCrm, ...arTools }
  },
  ch: {
    translation: { ...chCommon, ...chDashboard, ...chProfile, ...chEwallet, ...chEpin, ...chRegister, ...chTree, ...chPayout, ...chError, ...chShopping, ...chReplica, ...chLeads, ...chMailbox, ...chSubscription, ...chTicket ,...chCrm, ...chTools }
  },
  de: {
    translation: { ...deCommon, ...deDashboard, ...deProfile, ...deEwallet, ...deEpin, ...deRegister, ...deTree, ...dePayout, ...deError, ...deShopping, ...deReplica, ...deLeads, ...deMailbox, ...deSubscription, ...deTicket ,...deCrm, ...deTools }
  },
  fr: {
    translation: { ...frCommon, ...frDashboard, ...frProfile, ...frEwallet, ...frEpin, ...frRegister, ...frTree, ...frPayout, ...frError, ...frShopping, ...frReplica, ...frLeads, ...frMailbox, ...frSubscription, ...frTicket , ...frCrm, ...frTools }
  },
  it: {
    translation: { ...itCommon, ...itDashboard, ...itProfile, ...itEwallet, ...itEpin, ...itRegister, ...itTree, ...itPayout, ...itError, ...itShopping, ...itReplica, ...itLeads, ...itMailbox, ...itSubscription, ...itTicket , ...itCrm, ...itTools }
  },
  po: {
    translation: { ...poCommon, ...poDashboard, ...poProfile, ...poEwallet, ...poEpin, ...poRegister, ...poTree, ...poPayout, ...poError, ...poShopping, ...poReplica, ...poLeads, ...poMailbox, ...poSubscription, ...poTicket, ...poCrm, ...poTools }
  },
  pt: {
    translation: { ...ptCommon, ...ptDashboard, ...ptProfile, ...ptEwallet, ...ptEpin, ...ptRegister, ...ptTree, ...ptPayout, ...ptError, ...ptShopping, ...ptReplica, ...ptLeads, ...ptMailbox, ...ptSubscription, ...ptTicket ,...ptCrm, ...ptTools }
  },
  ru: {
    translation: { ...ruCommon, ...ruDashboard, ...ruProfile, ...ruEwallet, ...ruEpin, ...ruRegister, ...ruTree, ...ruPayout, ...ruError, ...ruShopping, ...ruReplica, ...ruLeads, ...ruMailbox, ...ruSubscription, ...ruTicket,  ...ruCrm, ...ruTools }
  },
  tr: {
    translation: { ...trCommon, ...trDashboard, ...trProfile, ...trEwallet, ...trEpin, ...trRegister, ...trTree, ...trPayout, ...trError, ...trShopping, ...trReplica, ...trLeads, ...trMailbox, ...trSubscription, ...trTicket ,...trCrm, ...trTools }
  },




  no: {
    translation: { ...noCommon, ...noDashboard, ...noProfile, ...noEwallet, ...noEpin, ...noRegister, ...noTree, ...noPayout, ...noError, ...noShopping, ...noReplica, ...noLeads, ...noMailbox, ...noSubscription, ...noTicket ,...noCrm, ...noTools }
  },
  sv: {
    translation: { ...svCommon, ...svDashboard, ...svProfile, ...svEwallet, ...svEpin, ...svRegister, ...svTree, ...svPayout, ...svError, ...svShopping, ...svReplica, ...svLeads, ...svMailbox, ...svSubscription, ...svTicket ,...svCrm, ...svTools }
  },
  th: {
    translation: { ...thCommon, ...thDashboard, ...thProfile, ...thEwallet, ...thEpin, ...thRegister, ...thTree, ...thPayout, ...thError, ...thShopping, ...thReplica, ...thLeads, ...thMailbox, ...thSubscription, ...thTicket ,...thCrm, ...thTools }
  },
  vi: {
    translation: { ...viCommon, ...viDashboard, ...viProfile, ...viEwallet, ...viEpin, ...viRegister, ...viTree, ...viPayout, ...viError, ...viShopping, ...viReplica, ...viLeads, ...viMailbox, ...viSubscription, ...viTicket ,...viCrm, ...viTools }
  },

  sr: {
    translation: { ...srCommon, ...srDashboard, ...srProfile, ...srEwallet, ...srEpin, ...srRegister, ...srTree, ...srPayout, ...srError, ...srShopping, ...srReplica, ...srLeads, ...srMailbox, ...srSubscription, ...srTicket ,...srCrm, ...srTools }
  },
  mk: {
    translation: { ...mkCommon, ...mkDashboard, ...mkProfile, ...mkEwallet, ...mkEpin, ...mkRegister, ...mkTree, ...mkPayout, ...mkError, ...mkShopping, ...mkReplica, ...mkLeads, ...mkMailbox, ...mkSubscription, ...mkTicket ,...mkCrm, ...mkTools }
  },
  sq: {
    translation: { ...sqCommon, ...sqDashboard, ...sqProfile, ...sqEwallet, ...sqEpin, ...sqRegister, ...sqTree, ...sqPayout, ...sqError, ...sqShopping, ...sqReplica, ...sqLeads, ...sqMailbox, ...sqSubscription, ...sqTicket ,...sqCrm, ...sqTools }
  },
  sl: {
    translation: { ...slCommon, ...slDashboard, ...slProfile, ...slEwallet, ...slEpin, ...slRegister, ...slTree, ...slPayout, ...slError, ...slShopping, ...slReplica, ...slLeads, ...slMailbox, ...slSubscription, ...slTicket ,...slCrm, ...slTools }
  },
  hr: {
    translation: { ...hrCommon, ...hrDashboard, ...hrProfile, ...hrEwallet, ...hrEpin, ...hrRegister, ...hrTree, ...hrPayout, ...hrError, ...hrShopping, ...hrReplica, ...hrLeads, ...hrMailbox, ...hrSubscription, ...hrTicket ,...hrCrm, ...hrTools }
  },
  sk: {
    translation: { ...skCommon, ...skDashboard, ...skProfile, ...skEwallet, ...skEpin, ...skRegister, ...skTree, ...skPayout, ...skError, ...skShopping, ...skReplica, ...skLeads, ...skMailbox, ...skSubscription, ...skTicket ,...skCrm, ...skTools }
  },
  el: {
    translation: { ...elCommon, ...elDashboard, ...elProfile, ...elEwallet, ...elEpin, ...elRegister, ...elTree, ...elPayout, ...elError, ...elShopping, ...elReplica, ...elLeads, ...elMailbox, ...elSubscription, ...elTicket ,...elCrm, ...elTools }
  },
  cs: {
    translation: { ...csCommon, ...csDashboard, ...csProfile, ...csEwallet, ...csEpin, ...csRegister, ...csTree, ...csPayout, ...csError, ...csShopping, ...csReplica, ...csLeads, ...csMailbox, ...csSubscription, ...csTicket ,...csCrm, ...csTools }
  },
  bg: {
    translation: { ...bgCommon, ...bgDashboard, ...bgProfile, ...bgEwallet, ...bgEpin, ...bgRegister, ...bgTree, ...bgPayout, ...bgError, ...bgShopping, ...bgReplica, ...bgLeads, ...bgMailbox, ...bgSubscription, ...bgTicket ,...bgCrm, ...bgTools }
  },
  ro: {
    translation: { ...roCommon, ...roDashboard, ...roProfile, ...roEwallet, ...roEpin, ...roRegister, ...roTree, ...roPayout, ...roError, ...roShopping, ...roReplica, ...roLeads, ...roMailbox, ...roSubscription, ...roTicket ,...roCrm, ...roTools }
  },


};

i18n
  .use(initReactI18next)
  .init({
    resources: mergedTranslations,
    lng: (savedLanguage?.code) ? savedLanguage?.code : 'en',
    interpolation: {
      escapeValue: false,
    },
  });


export default i18n;
