import { useTranslation } from "react-i18next";
import Videos from "../../components/Videos/Videos";
import { ApiHook } from "../../hooks/apiHook";
import React, { useState } from 'react'
import VideoPagination from "../../components/Videos/VideoPagination";

export default function VideoList() {
    const [page, setPage] = useState(1)
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { t } = useTranslation();
    const videos = ApiHook.CallVideoList(page, 4)
    const handlePageChange = (newPage) => {
        setPage(newPage);
    };
    return (
        <div>
            <div className="page_head_top">{t("videos")}</div>
            <div className="ewallet_top_btn_sec">
                <div className="row justify-content-between">
                    {videos?.data?.videos.length > 0 ?
                        <Videos
                            list={videos?.data?.videos}
                            show={show}
                            handleClose={handleClose}
                            handleShow={handleShow}
                        />
                        :
                        <div className="nodata-table-view">
                            <div className="nodata-table-view-box">
                                <div className="nodata-table-view-box-img">
                                    <img src="/images/no-data-image1.jpg" alt="" />
                                </div>
                                <div className="nodata-table-view-box-txt">
                                    {t("sorry_no_data_found")}
                                </div>
                            </div>
                        </div>}
                    {videos?.data?.totalPages > 1 && (
                        <VideoPagination
                            currentPage={page}
                            totalPages={videos?.data?.totalPages}
                            onPageChange={handlePageChange}
                        />
                    )}
                </div>
            </div>
        </div>

    )
}
