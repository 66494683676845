import React from 'react';

const VideoPagination = ({ currentPage, totalPages, onPageChange }) => {
    const handlePrevPage = () => {
        if (currentPage > 1) onPageChange(currentPage - 1);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) onPageChange(currentPage + 1);
    };

    return (
        <div className='bottom-fixed'>
            <i className={`fa-solid fa-circle-arrow-left fa-2xl ${currentPage > 1 ? "video-nav" : ""}`} onClick={handlePrevPage} disabled={currentPage <= 1}>
            </i>
            <div className="circle-number">{currentPage}</div>
            <i className={`fa-solid fa-circle-arrow-right fa-2xl ${currentPage < totalPages ? "video-nav" : ""}`} onClick={handleNextPage} disabled={currentPage >= totalPages}>
            </i>
        </div>

    );
};

export default VideoPagination;