import React, { useState } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import VideoModal from './VideoModal'
import { useTranslation } from 'react-i18next'

export default function Videos({ list, show, handleClose, handleShow }) {
    const { t } = useTranslation()
    const [video, setVideo] = useState({})
    const handlePlay = (data) => {
        setVideo(data)
        handleShow()
    }
    return (

        <>
            <Row >
                {list?.map((e) => {
                    return (<>
                        <Col className='video-tile' md={"3"}>
                            <Card style={{ width: '20rem' }}>
                                <div style={{width:"100%",height:"250px"}}>
                                <Card.Img  /* className="video-image" */ style={{width:"100%",height:"100%", padding:"5px", borderRadius:"10px"}} src={e?.image ?? "/images/product3.jpg"} />
                                </div>
                                <Card.Body>
                                    <Card.Title>{e.name}</Card.Title>
                                    <Card.Text style={{ /* height: "150px", */ overflow: "auto" }}>
                                        {e.description}
                                    </Card.Text>
                                    <Button variant="primary" onClick={() => handlePlay(e)}>{
                                        <>
                                            <i class="fa-solid fa-play" style={{ paddingRight: "5px" }}></i>{t("play")}
                                        </>
                                    }</Button>
                                </Card.Body>
                            </Card>

                        </Col>
                    </>)
                })}


            </Row>
            <VideoModal show={show} handleClose={handleClose} video={video} />
        </>)
}
